// store.ts
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import searchResultsReducer, { searchResultsSlice } from "./search/reducer";
import searchDetailsReducer from "./searchDetails/reducer";
import segnalaSentenzaReducer from "./segnalaSentenza/reducer";
import { configSlice } from "./config/reducer";
import { resultsSlice } from "./searchResults/reducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  searchResults: searchResultsReducer,
  searchDetails: searchDetailsReducer,
  setSearchBarFilter: searchResultsSlice.reducer,
  segnalaSentenza: segnalaSentenzaReducer,
  config: configSlice.reducer,
  searchResponse: resultsSlice.reducer,
});

// Persistiamo solo "searchResults". Puoi aggiungere altri se necessario.
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["searchResults"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
