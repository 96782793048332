import React from "react";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { setModRicerca, setModRicercaDescrizione, setCampoRicerca } from "../store/search/reducer";
import { useAppDispatch, useAppSelector } from "../store/store";
import './styles.css';

type SearchBarProps = {
  filterList: Array<any>
  isError?: boolean,
  errorMessage?: string,
  initialValueCampoRicerca: string,
  initialValueModRicerca: string,
  disabled?: boolean;
  title?: string;
  required?: boolean
}

const SearchBar = (props: SearchBarProps) => {

  const dispatch = useAppDispatch();
  const { request } = useAppSelector((state) => state.searchResults);

  const onClickItem = (val: any) => {
    dispatch(setModRicerca(val.descrizione))
    dispatch(setModRicercaDescrizione(val.codice))
  }

  return (
    <>
      <Form.Label htmlFor="input-group-dropdown-1" className="form-label">
        {props.title}:{props.required ? '*' : null}
      </Form.Label>
       <i className="bi bi-question-circle-fill-pers mx-2 info-cursor fs-3" data-bs-toggle="modal" data-bs-target="#search-modal" title="Informazioni sui filtri di ricerca"></i>
          
      <InputGroup className="py-1 mb-2">
        <DropdownButton
          variant="outline-primary"
          title={request?.filter?.modRicercaDescrizione}
          disabled={props.disabled}
        >
          {
            props.filterList.map((val, idx) =>
              <Dropdown.Item key={idx} onClick={() => onClickItem(val)}>{val.codice}</Dropdown.Item>
            )
          }
        </DropdownButton>
        <Form.Control
          disabled={props.disabled}
          value={props.initialValueCampoRicerca}
          placeholder="Cerca"
          aria-label="Text input with dropdown button"
          onChange={(e) => dispatch(setCampoRicerca(e.target.value))}
          className={props.isError ? "form-control is-invalid" : ""}
          id="input-group-dropdown-1"
        />
        <div id="ex-input-invalid-feedback" className={`invalid-feedback ${props.isError ? 'display-class' : 'display-class-unset'}`} role="alert">{props.errorMessage}</div>
      </InputGroup>
    </>
  );
}

export default SearchBar;
