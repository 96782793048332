// SentenceAccordion.tsx
import { Accordion, Col, Row, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../store/store";
import { doSequentialSearches } from "../utils/pdfSearchUtils";

interface SentenceAccordionProps {
    urlFile: string;
    ready: boolean;
    //showWarning: boolean;
    //warningContent: string;
    open: boolean;
    enableSearch?: boolean;
    showInfo: boolean;
	infoContent: string;
	//alessandra 03/03/2025 aggiunta flag per gestione sentenze cartacee
	showInfoCartacee: boolean;
	infoCartaceeContent: string;
	//    
	
}

const SentenceAccordion = (props: SentenceAccordionProps) => {
    // -------------- STATE e REF --------------
    const adobeViewerRef = useRef<HTMLDivElement | null>(null);
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const apisRef = useRef<any>(null);

    // Leggiamo da Redux
    const campoRicerca = useAppSelector((state) => state.searchResults.request.filter.campoRicerca);
    const modRicerca = useAppSelector((state) => state.searchResults.request.filter.modRicerca);

    // Stato per memorizzare le stopwords lette da XML
    const [customStopwords, setCustomStopwords] = useState<string[]>([]);

    // Stato per memorizzare il numero di risultati di ogni termine
    const [resultsCount, setResultsCount] = useState<{ [term: string]: number }>({});

    // Stato per indicare quale termine è “attivo”
    const [activeTerm, setActiveTerm] = useState<string | null>(null);

    // -------------- CARICAMENTO STOPWORDS PERSONALIZZATE --------------
    useEffect(() => {
        // Carichiamo l'XML con le nostre stopwords
        fetch("/statici/DocTribStopwordutf8.xml")
            .then((response) => response.text())
            .then((xmlString) => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xmlString, "application/xml");

                // Estraggo tutti i nodi <stopWord> e ne ricavo il testo
                const stopWordElements = Array.from(xmlDoc.getElementsByTagName("stopWord"));
                const stopWordsList = stopWordElements
                    .map((el) => el.textContent?.trim().toLowerCase())
                    .filter((word): word is string => !!word);

                setCustomStopwords(stopWordsList);
            })
            .catch((err) => console.error("Errore nel caricamento delle stopwords personalizzate:", err));
    }, []);

    // -------------- CALCOLO DEI TERMINI DI RICERCA --------------

    // 1) Partiamo dal testo originale (campoRicerca) e lo trimmiamo.
    let testoInput = campoRicerca.trim();

    // 2) Troviamo tutte le sottostringhe racchiuse tra virgolette con una regex.
    //    - "([^"]+)" cattura tutto ciò che sta tra due doppi apici.
    //    - matchAll ci permette di iterare su tutte le occorrenze.
    const quotedPhrases: string[] = [];
    testoInput = testoInput.replace(/"([^"]+)"/g, (_, group1) => {
        // group1 è il contenuto interno alle virgolette, che vogliamo trattare come token singolo.
        quotedPhrases.push(group1.trim());
        // Rimuoviamo queste occorrenze dal testo (sostituendole con una stringa vuota).
        return "";
    });

    // 3) A questo punto, nel testoInput potrebbero rimanere virgolette isolate o
    //    spazi multipli generati dalla rimozione delle frasi quotate. Ripuliamolo ulteriormente.
    //    Rimuoviamo eventuali virgolette rimaste.
    testoInput = testoInput.replace(/["]/g, "");

    // 4) Splittiamo il testo rimanente sugli spazi.
    let splittedWords = testoInput.split(/\s+/).filter((w) => w !== "");

    // 5) Se la modalità di ricerca NON è "F", rimuoviamo le parole AND, OR, NOT in maiuscolo
    //    (come facevi prima).
    if (modRicerca !== "F") {
        splittedWords = splittedWords.filter(
            (word) => !["AND", "OR", "NOT"].includes(word)
        );
    }

    // 6) Convertiamo in minuscolo sia le parole splittate sia le frasi tra virgolette.
    splittedWords = splittedWords.map((w) => w.toLowerCase());
    const normalizedQuotedPhrases = quotedPhrases.map((qp) => qp.toLowerCase());

    // 7) A questo punto uniamo i token “quotati” (ognuno è un singolo token) con quelli “splittati”.
    let allTokens = [...normalizedQuotedPhrases, ...splittedWords];

    // 8) In base alla modRicerca gestiamo la logica finale, compresa l’eventuale applicazione di stopwords:
    let searchTerms: string[] = [];

    /**
     * - Se la modRicerca è "F", la tua logica dice di creare un singolo “termine”
     *   con l’intero testo in minuscolo; in tal caso ignoriamo i token precedenti
     *   e teniamo l’intero input come un’unica stringa.
     *   Tuttavia, se preferisci che "F" mantenga comunque i token quotati come unico blocco,
     *   puoi modificarlo secondo le tue esigenze.
     */
    if (modRicerca === "F") {
        // Ricerca “frase esatta”
        // => un solo termine che è l’intera stringa già normalizzata.
        //    Se vuoi includere ANCHE le parti quotate come blocco unico, potresti
        //    farlo diversamente. Ma seguendo la logica esistente:
        searchTerms = [allTokens.join(" ")];

    } else if (modRicerca === "C") {
        // Ricerca con operatori logici (C).
        // => includiamo i token “allTokens” (senza rimuovere stopwords).
        searchTerms = allTokens;
    } else {
        // Tutte le altre modalità: rimuoviamo anche le stopwords personalizzate.
        // => togliamo dal nostro array i token presenti nella lista customStopwords
        searchTerms = allTokens.filter((token) => !customStopwords.includes(token));
    }

    // -- LOGGARE PER VERIFICA --
    console.log("--------- LOG DI VERIFICA STOPWORDS e QUOTES ---------");
    console.log("Testo originale (campoRicerca):", campoRicerca);
    console.log("Frasi quotate (quotedPhrases):", quotedPhrases);
    console.log("Parte rimanente dopo rimozione frasi quotate (testoInput):", testoInput);
    console.log("Splitted words:", splittedWords);
    console.log("Stopwords caricate da XML:", customStopwords);
    console.log("Modalità di ricerca (modRicerca):", modRicerca);
    console.log("Risultato finale -> searchTerms:", searchTerms);
    console.log("------------------------------------------------------");

    // -------------- EFFECT: ANTEPRIMA E RICERCA NEL PDF --------------
    useEffect(() => {
        if (props.ready && adobeViewerRef.current) {
            const adobeDCView = new (window as any).AdobeDC.View({
                clientId: clientId,
                divId: "adobe-pdf-viewer",
            });

            adobeDCView
                .previewFile(
                    {
                        content: {
                            location: { url: props.urlFile },
                        },
                        metaData: {
                            fileName: "Sentenza.pdf",
                            id: "unique-file-id",
                        },
                    },
                    {
                        embedMode: "SIZED_CONTAINER",
                        defaultViewMode: "FIT_PAGE",
                        enableAnnotationAPIs: true,
                        enableSearchAPIs: props.enableSearch || false,
                        showDownloadPDF: true,
                        showPrintPDF: true,
                        showZoomControl: false,
                        showPageControls: true,
                        showSearch: props.enableSearch || false,
                        includePDFAnnotations: true,
                    }
                )
                .then((adobeViewer: any) => {
                    if (props.enableSearch) {
                        return adobeViewer.getAPIs();
                    }
                })
                .then((apis: any) => {
                    if (props.enableSearch) {
                        apisRef.current = apis;
                        // Lanciamo la ricerca sequenziale
                        doSequentialSearches(apis, searchTerms, setResultsCount)
                            .then((finalCounts) => {
                                // Al termine della ricerca sequenziale abbiamo in `finalCounts` 
                                // i conteggi finali di tutti i termini.
                                // Cerchiamo il primo che abbia un risultato > 0.
                                const firstFoundTerm = searchTerms.find((term) => {
                                    return finalCounts[term] && finalCounts[term] > 0;
                                });

                                // Se esiste, lo evidenziamo
                                if (firstFoundTerm)
                                    handleSearchClick(firstFoundTerm);
                            })
                            .catch((err) => console.error("Errore in doSequentialSearches:", err));
                    }
                })
                .catch((error: any) => {
                    console.error("[SentenceAccordion] Errore in previewFile/getAPIs:", error);
                });
        }
    }, [props.ready, props.urlFile, props.enableSearch, customStopwords]);

    // -------------- HANDLERS --------------

    // Ricerca "on demand" (clic sui bottoni) - evidenzia effettivamente il termine
    const handleSearchClick = (term: string) => {
        if (!apisRef.current || typeof apisRef.current.search !== "function") {
            console.warn("[SentenceAccordion] APIs non pronte, impossibile cercare:", term);
            return;
        }

        apisRef.current
            .search(term)
            .then((result: any) => {
                // Quando l’utente (o il codice) fa click su un termine, lo rendiamo “attivo”
                setActiveTerm(term);
                if (typeof result.onResultsUpdate === "function") {
                    result.onResultsUpdate((update: any) => {
                        console.log(`[SentenceAccordion] handleSearchClick -> onResultsUpdate("${term}"):`, update);
                    });
                }
            })
            .catch((error: any) => {
                console.error(`[SentenceAccordion] handleSearchClick -> Errore search "${term}":`, error);
            });
    };

    // -------------- RENDER --------------
    return (
        <Accordion defaultActiveKey={props.open ? "0" : ""} className="py-2">
            <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header>
                    <div className="d-flex flex-wrap align-items-center">
                        <small>Sentenza</small>
                    </div>
                </Accordion.Header>

                <Accordion.Body>
                    { /*{props.showWarning && (<div id="alert-warning" className="alert alert-warning" role="alert">{props.warningContent} </div>)}*/}
                    {props.showInfo && <div id="alert-info" className="alert alert-info" role="alert">{props.infoContent}</div>}
					{props.showInfoCartacee && <div id="alert-info" className="alert alert-info" role="alert">{props.infoCartaceeContent}</div>}
	                {/* Mostriamo i pulsanti solo se ce n'è almeno uno con risultati > 0 */}
                    {props.enableSearch && searchTerms.length > 0 && (() => {
                        const buttonsToRender = searchTerms
                            .filter((term) => resultsCount[term] && resultsCount[term] > 0)
                            .map((term, index) => (
                                <Button
                                    key={index}
                                    variant={term === activeTerm ? "primary" : "outline-primary"}
                                    size="sm"
                                    className={`ms-2 ${term === activeTerm ? "active" : ""}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSearchClick(term);
                                    }}
                                >
                                    {term}
                                </Button>
                            ));

                        if (buttonsToRender.length === 0)
                            return null;

                        return (
                            <div className="my-2">
                                <strong>Termini ricercati:</strong>
                                {buttonsToRender}
                            </div>
                        );
                    })()}

                    
                    <Row className="my-0">
                        <Col className="width-all col-md-6">
                            <div
                                id="adobe-pdf-viewer"
                                ref={adobeViewerRef}
                                style={{ height: "1000px", width: "100%" }}
                                className="pdf-viewer-container px-1 py-1 align-items-center justify-content-center"
                            ></div>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default SentenceAccordion;