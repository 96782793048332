// pdfSearchUtils.ts
export async function doSequentialSearches(
    apis: any,
    terms: string[],
    setResultsCount: React.Dispatch<React.SetStateAction<{ [term: string]: number }>>
): Promise<{ [term: string]: number }> {
    const finalCounts: { [term: string]: number } = {};

    for (const term of terms) {
        console.log(`[SentenceAccordion] Inizio ricerca per: "${term}"`);
        try {
            const result = await apis.search(term);
            if (typeof result.onResultsUpdate === "function") {
                await new Promise<void>((resolve) => {
                    result.onResultsUpdate((update: any) => {
                        if (update && typeof update.totalResults === "number") {
                            setResultsCount((prev) => ({ ...prev, [term]: update.totalResults }));
                            finalCounts[term] = update.totalResults;
                        }
                        resolve();
                    });
                });
            }
        } catch (error) {
            console.error(`[SentenceAccordion] Errore search "${term}": `, error);
        } finally {
            if (typeof apis.clearSearch === "function") {
                try {
                    await apis.clearSearch();
                } catch (err) {
                    console.error("[SentenceAccordion] Errore in clearSearch()", err);
                }
            }
        }
    }

    return finalCounts;
}
