import React, { useEffect, useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../store/store";
import { CONSTANTS } from "../utils/constants";
import SearchItem from "./SearchItem";
import {
  setEmissioneInizio,
  setEmissioneFine,
  setGradoAutorita,
  setAutoritaEmittente,
  setValoreControversiaMin,
  setValoreControversiaMax,
  setTipoGiudizio,
  setEsitoGiudizio,
  setMateria,
  setClassificazione,
  setValoreControversia,
  setSpeseGiudizio,
  setSentenzaImpugnata,
  setRequestAfterRefresh
} from "../store/search/reducer";
import './styles.css';

type AdvancedResearchProps = {
  autorityGradeList: Array<any>,
  autorityList: Array<any>,
  disputeList: Array<any>,
  judgementTypeList: Array<any>,
  judgementOutcomeList: Array<any>
  matterList: Array<any>,
  classificationList: Array<any>,
  showLoader?: boolean,
  speseGiudizioList: Array<any>,
  sentenzaImpugnataList: Array<any>,
  unmountDataInizio: boolean,
  unmountDataFine: boolean
}

const AdvancedResearch = (props: AdvancedResearchProps) => {

  const dispatch = useAppDispatch();

  const [disabledAutorita, setDisabledAutorita] = useState(true)
  const [disabledSentenzaImpugnata, setDisabledSentenzaImpugnata] = useState(true)
  const [valueAutoritaEmittente, setValueAutoritaEmittente] = useState("")
  const [unmountAutorita, setUnmountAutorita] = useState(false)

  const requestFromLocal = JSON.parse(localStorage.getItem('request') as string)
  const { request, valoreControversia } = useAppSelector((state) => state.searchResults);

  const dateFromSelectedCallback = (val: string) => {
    dispatch(setEmissioneInizio(val))
  }

  const dateUntilSelectedCallback = (val: string) => {
    dispatch(setEmissioneFine(val))
  }

  const autorityGradeSelectedCallback = (val: string) => {
    dispatch(setGradoAutorita(val))
    dispatch(setAutoritaEmittente([]))
  }

  const autoritySelectedCallback = (val: string) => {
    dispatch(setAutoritaEmittente(val))
  }

  const sentenzaImpugnataCallback = (val: string) => {
    dispatch(setSentenzaImpugnata(val))
  }

  const disputeValueSelectedCallback = (val: string) => {
    const minValue = props.disputeList.filter((value) => value.descrizione === val)[0]?.minValue
    const maxValue = props.disputeList.filter((value) => value.descrizione === val)[0]?.maxValue
    dispatch(setValoreControversiaMin(minValue))
    dispatch(setValoreControversiaMax(maxValue))
    dispatch(setValoreControversia(val))
  }

  const judgementTypeSelectedCallback = (val: string) => {
    dispatch(setTipoGiudizio(val))
  }

  const judgementOutcomeSelectedCallback = (val: string) => {
    dispatch(setEsitoGiudizio(val))
  }

  const matterSelectedCallback = (val: string) => {
    dispatch(setMateria(val))
    dispatch(setClassificazione(""))
  }

  const classificationSelectedCallback = (val: string) => {
    dispatch(setClassificazione(val))
  }

  const speseGiudizioSelectedCallback = (val: string) => {
    dispatch(setSpeseGiudizio(val))
  }

  const reformatDate = (date: any) => {
    let finalDate = ""
    if (date) {
      const year = date.toString().substring(0, 4)
      const month = date.toString().substring(4, 6)
      const day = date.toString().substring(6)
      finalDate = `${year}-${month}-${day}`
    }
    return finalDate;
  }

  useEffect(() => {
    if (request?.filter?.gradoAutorita !== '') {
      setDisabledAutorita(false)
      if (request?.filter?.gradoAutorita === "0") {
        dispatch(setSentenzaImpugnata(""))
        setDisabledSentenzaImpugnata(true)
        setValueAutoritaEmittente('descrizione')
      }
      else {
        setValueAutoritaEmittente('codice')
        if (request?.filter?.gradoAutorita === "1") {
          setDisabledSentenzaImpugnata(false)
        }
        else {
          dispatch(setSentenzaImpugnata(""))
          setDisabledSentenzaImpugnata(true)
        }
      }
    }
    else {
      setDisabledAutorita(true)
      dispatch(setSentenzaImpugnata(""))
      setDisabledSentenzaImpugnata(true)
    }
  }, [request?.filter?.gradoAutorita])

  useEffect(() => {
    setUnmountAutorita(true)
    setTimeout(() => {
      setUnmountAutorita(false)
    }, 0);
  }, [request?.filter?.gradoAutorita])

  useEffect(() => {
    if (requestFromLocal)
      dispatch(setRequestAfterRefresh(requestFromLocal))  
  }, [])

  return (
    <Accordion className="py-2" defaultActiveKey="0">
      <Accordion.Item className="accordion-item" eventKey="0">
        <Accordion.Header className="accordion-header">
          <small>{CONSTANTS.SEARCH_PAGE.advancedResearchTitle}
           <i className="bi bi-question-circle-fill-pers mx-2 info-cursor fs-3" data-bs-toggle="modal" data-bs-target="#search-modal-avanzata" title="Informazioni sui filtri di ricerca" onClick={(e) => e.stopPropagation()}></i>
          </small>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col className="col-auto">
              <SearchItem
                initialValue={request?.filter?.gradoAutorita}
                value="descrizione"
                id="Form.ControlInput5"
                title="Grado autorità emittente"
                type="select"
                list={props.autorityGradeList}
                callback={autorityGradeSelectedCallback}
                disabled={props.showLoader}
              />
            </Col>
            <Col className="col-auto">
              {unmountAutorita ?
                null
                :
                <SearchItem
                  initialValue={request?.filter?.autoritaEmittente[0]}
                  value={valueAutoritaEmittente}
                  disabled={disabledAutorita || props.showLoader}
                  id="Form.ControlInput6"
                  title="Autorità emittente"
                  type="multiple-select"
                  list={props.autorityList}
                  field='descrizione'
                  callback={autoritySelectedCallback}
                />
              }
            </Col>
            <Col className="col-auto">
              <SearchItem
                initialValue={request?.filter?.sentenzaImpugnata}
                value="descrizione"
                id="Form.ControlInput12"
                title="Sentenza impugnata"
                type="select"
                list={props.sentenzaImpugnataList}
                callback={sentenzaImpugnataCallback}
                note={true}
                disabled={disabledSentenzaImpugnata}
              />
            </Col>
          </Row>
          <Row id={'date-row'}>
            <Col className="col-auto">
              {props.unmountDataInizio ?
                null
                :
                <SearchItem
                  initialValue={reformatDate(request?.filter?.emissioneInizio)}
                  value=""
                  id="Form.ControlInput3"
                  title="Data deposito da"
                  placeholder="Data deposito da"
                  type="date"
                  callback={dateFromSelectedCallback}
                  keyboardCallback={dateFromSelectedCallback}
                  disabled={props.showLoader}
                />
              }
            </Col>
            <Col className="col-auto">
              {props.unmountDataFine ?
                null
                :
                <SearchItem
                  initialValue={reformatDate(request?.filter?.emissioneFine)}
                  value=""
                  id="Form.ControlInput4"
                  title="Data deposito fino a"
                  placeholder="Data deposito fino a"
                  type="date"
                  callback={dateUntilSelectedCallback}
                  keyboardCallback={dateUntilSelectedCallback}
                  disabled={props.showLoader}
                />
              }
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <SearchItem
                initialValue={valoreControversia}
                value='descrizione'
                id="Form.ControlInput7"
                title="Valore controversia"
                type="select"
                field='descrizione'
                list={props.disputeList}
                callback={disputeValueSelectedCallback}
                disabled={props.showLoader}
              />
            </Col>
            <Col className="col-auto">
              <SearchItem
                initialValue={request?.filter?.tipoGiudizio}
                value="descrizione"
                id="Form.ControlInput8"
                title="Tipo giudizio"
                type="select"
                list={props.judgementTypeList}
                callback={judgementTypeSelectedCallback}
                disabled={props.showLoader}
              />
            </Col>
            <Col className="col-auto">
              <SearchItem
                initialValue={request?.filter?.esitoGiudizio}
                value="codiceMacroesito"
                id="Form.ControlInput9"
                title="Esito giudizio"
                type="select"
                field='descrizioneMacroesito'
                list={props.judgementOutcomeList}
                callback={judgementOutcomeSelectedCallback}
                disabled={props.showLoader}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <SearchItem
                initialValue={request?.filter?.materia}
                value="codiceMateria"
                id="Form.ControlInput10"
                title="Materia"
                type="select"
                field='descrizioneMateria'
                list={props.matterList}
                callback={matterSelectedCallback}
                disabled={props.showLoader}
              />
            </Col>
            <Col className="col-auto">
              <SearchItem
                initialValue={request?.filter?.flagSpeseGiudizio}
                value="descrizione"
                disabled={props.showLoader}
                id="Form.ControlInput11"
                title="Spese Giudizio"
                type="select"
                field='codice'
                list={props.speseGiudizioList}
                callback={speseGiudizioSelectedCallback}
              />
            </Col>
          </Row>
          <p className="mt-3">** Solo per le sentenze di primo grado.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AdvancedResearch;
